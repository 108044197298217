body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  max-width: 100%;
  overflow-x: hidden;
  /* font-family: 'Martel Sans', */

}
*{
  max-width: 100%;
  /* font-family: 'Martel Sans !important', */

}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "airbnb-cereal-app";
  src: local("GoldmanBold"),
   url("./fonts/AirbnbCerealMedium.ttf") format("truetype");
  font-weight: normal;
  }

  @font-face {
    font-family: "airbnb-cereal-app-light";
    src: local("GoldmanBold"),
     url("./fonts/AirbnbCerealLight.ttf") format("truetype");
    font-weight: light;
    }
  
